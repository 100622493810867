import { useEffect, useState } from "react";
import Organization from "../../models/organization";
import organizationService from "../../services/organizationService";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import AdminBoxConent from "../../components/admin/AdminBoxContent";

const Organizations: React.FC = () => {
  const navigate = useNavigate();

  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [allOrganizations, setAllOrganizations] = useState<Organization[]>([]);

  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    const init = async () => {
      const { data: organizations } =
        await organizationService.getOrganizations();
      setOrganizations(organizations);
      setAllOrganizations(organizations);
      setSearchText("");
    };

    init();
  }, []);

  const onNewOrganization = () => {
    navigate("/admin/organizations/new");
  };

  const onEditOrganization = (organization: Organization) => {
    navigate(`/admin/organizations/${organization.idOrganization}`);
  };

  const onSearchTextChange = (event: any) => {
    const value = event.target.value;
    setSearchText(value);

    const searchToken = event.target.value.toLowerCase();

    const filteredOrganizations = allOrganizations.filter(
      (o) =>
        o.organizationName.toLowerCase().includes(searchToken) ||
        o.organizationCode.toLowerCase().includes(searchToken) ||
        o.contactPersonFirstName?.toLowerCase().includes(searchToken) ||
        o.contactPersonLastName?.toLowerCase().includes(searchToken) ||
        o.contactPersonEmail?.toLowerCase().includes(searchToken)
    );

    setOrganizations(filteredOrganizations);
  };

  return (
    <AdminBoxConent
      title="Organizations"
      onAddNew={() => onNewOrganization()}
      count={organizations.length}
      countType="organizations"
      hasCurrentOrganizationDropdown={false}
    >
      <div className="mb-3">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={searchText}
            onChange={onSearchTextChange}
          />
          <button type="submit" className="btn btn-primary">
            Search
          </button>
        </div>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Organization Name</th>
            <th scope="col">Organization Code</th>
            <th scope="col">Contact Person First Name</th>
            <th scope="col">Contact Person Last Name</th>
            <th scope="col">Contact Person Email</th>
            <th style={{ width: "50px" }} />
          </tr>
        </thead>
        <tbody>
          {organizations.map((organization: Organization) => (
            <tr key={organization.idOrganization.toString()}>
              <td>{organization.organizationName}</td>
              <td>{organization.organizationCode}</td>
              <td>{organization.contactPersonFirstName}</td>
              <td>{organization.contactPersonLastName}</td>
              <td>{organization.contactPersonEmail}</td>
              <td>
                <EditIcon
                  style={{ cursor: "pointer" }}
                  fontSize="small"
                  onClick={() => onEditOrganization(organization)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </AdminBoxConent>
  );
};

export default Organizations;
