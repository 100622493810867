import NetworkPosition from "./networkPosition";

export default class AppUrlParams {
  public hasUrlParams: boolean = false;
  public urlParamsString: string = "";

  public hasProject: boolean = false;
  public project: string = "";

  public hasLatitude: boolean = false;
  public latitude: number = 0;

  public hasLogitude: boolean = false;
  public longitude: number = 0;

  public hasNetworkPosition: boolean = false;
  public networkPosition: NetworkPosition | undefined = undefined;

  public hasLatLon(): boolean {
    return this.hasLatitude && this.hasLogitude;
  }

  public clear() {
    this.hasUrlParams = false;
  }
}
