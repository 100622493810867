import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SignupPending from "../../models/signupPending";
import userService from "../../services/userService";
import BoxContent from "../../components/common/BoxContent";
import formHelper from "../../util/formHelper";
import Validator from "../../components/common/Validator";
import ValidatorManager from "../../components/common/validationManager";
import ModalWindow from "../../components/common/overlays/ModalWindow";

const validator = {
  email: "email",
};

const SignupPendingEdit: React.FC<{}> = () => {
  const { id: idParam } = useParams();
  const navigate = useNavigate();

  const [showDeleteWindow, setShowDeleteWindow] = useState<boolean>(false);

  const [validatorManager] = useState<ValidatorManager>(new ValidatorManager());

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [signupPending, setSignupPending] = useState<SignupPending>(
    new SignupPending()
  );

  useEffect(() => {
    const initEdit = async (idSignupPending: number) => {
      const { data: signupPending } = await userService.getSignupPendingById(
        idSignupPending
      );

      setSignupPending(signupPending);
    };

    const idSignupPending = Number(idParam);
    initEdit(idSignupPending);
  }, [idParam]);

  const onSignupPendingChanged = (event: any) => {
    const newValue = event.target.value;

    const newSignupPending = {
      ...signupPending,
    };

    if (newSignupPending.user) {
      newSignupPending.user.email = newValue;
      newSignupPending.user.username = newValue;
    }

    setSignupPending(newSignupPending);

    const isValid = newValue.length !== 0;
    validatorManager.setIsValid(validator.email, isValid);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setErrorMessage("");

    try {
      if (signupPending.user) {
        await userService.updateSignupPending(
          signupPending.idSignupPending,
          signupPending.user.email
        );
      }
    } catch (error: any) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }

    navigateAway();
  };

  const onDeleteConfirm = () => {
    setShowDeleteWindow(true);
  };

  const onDeleteOk = async () => {
    setShowDeleteWindow(false);
    await userService.deleteSignupPending(signupPending.idSignupPending);
    navigateAway();
  };

  const onDeleteCancel = () => {
    setShowDeleteWindow(false);
  };

  const onCancel = () => {
    navigateAway();
  };

  const navigateAway = () => {
    navigate("/admin/signup-pendings");
  };

  return (
    <BoxContent title="Edit Signup Pending" useFullWidth>
      <div className="d-flex mb-3">
        <div style={{ fontWeight: "bold", marginRight: "10px" }}>
          Organizaiton
        </div>
        <div>{signupPending.organization?.organizationName}</div>
      </div>
      <form
        className="input-form"
        onSubmit={onSubmit}
        onKeyDown={formHelper.onEnterKeyDownPreventSubmit}
      >
        <div className="row">
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <div>
              <Validator
                name={validator.email}
                errorMessage="Please enter an email."
                validationManager={validatorManager}
              >
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  value={signupPending.user?.email ?? ""}
                  onChange={onSignupPendingChanged}
                />
              </Validator>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <button type="submit" className="btn btn-primary">
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              style={{ marginLeft: "10px" }}
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-danger"
              onClick={onDeleteConfirm}
            >
              Delete
            </button>
          </div>
        </div>
        <div className="col-md-12 text-center mt-3">
          {errorMessage !== "" && (
            <div className="error-message">{errorMessage}</div>
          )}
        </div>
      </form>
      {showDeleteWindow && (
        <ModalWindow title="Delete" onOk={onDeleteOk} onCancel={onDeleteCancel}>
          <div>{`Are you sure you want to delete the signup pending?`}</div>
        </ModalWindow>
      )}
    </BoxContent>
  );
};

export default SignupPendingEdit;
