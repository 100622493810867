import Organization from "../models/organization";
import OrganizationStat from "../models/organizationStat";
import SignupDomain from "../models/signupDomain";
import httpService from "./httpService";

const apiEndpoint = "/organizations";

const getOrganizations = () => {
  return httpService.get<Organization[]>(`${apiEndpoint}/getOrganizations`);
};

const getOrganizationById = (idOrganization: number) => {
  return httpService.get<Organization>(
    `${apiEndpoint}/getOrganizationById?idOrganization=${idOrganization}`
  );
};

const getOrganizationStats = () => {
  return httpService.get<OrganizationStat[]>(
    `${apiEndpoint}/getOrganizationStats`
  );
};

const getOrganizationStatsCsvFile = () => {
  return httpService.get(`${apiEndpoint}/getOrganizationStatsCsvFile`);
};

const getOrganizationUserStatsCsvFile = () => {
  return httpService.get(`${apiEndpoint}/getOrganizationUserStatsCsvFile`);
};

const createOrganization = (
  organizationName: string,
  organizationCode: string,
  signupUserGroupName: string,
  isPaidSubscription: boolean,
  subscriptionRenewalDate: Date | undefined,
  goLiveDate: Date | undefined
) => {
  return httpService.post<Organization>(`${apiEndpoint}/createOrganization`, {
    organizationName,
    organizationCode,
    signupUserGroupName,
    isPaidSubscription,
    subscriptionRenewalDate,
    goLiveDate,
  });
};

const updateOrganization = (organization: Organization) => {
  return httpService.post(`${apiEndpoint}/updateOrganization`, organization);
};

const getSignupDomains = (idOrganization: number) => {
  return httpService.get<SignupDomain[]>(
    `${apiEndpoint}/getSignupDomains?idOrganization=${idOrganization}`
  );
};

const getSignupDomainById = (idSignupDomain: number) => {
  return httpService.get<SignupDomain>(
    `${apiEndpoint}/getSignupDomainById?idSignupDomain=${idSignupDomain}`
  );
};

const createSignupDomain = (signupDomain: SignupDomain) => {
  return httpService.post<SignupDomain>(
    `${apiEndpoint}/createSignupDomain`,
    signupDomain
  );
};

const updateSignupDomain = (signupDomain: SignupDomain) => {
  return httpService.post<SignupDomain>(
    `${apiEndpoint}/updateSignupDomain`,
    signupDomain
  );
};

const deleteSignupDomain = (idSignupDomain: number) => {
  var data = new FormData();
  data.append("idSignupDomain", idSignupDomain.toString());
  return httpService.post(`${apiEndpoint}/deleteSignupDomain`, data);
};

const isIccOrganization = (organization: Organization | undefined): boolean => {
  return organization?.organizationCode.toLowerCase() !== "icc";
};

const organizationService = {
  getOrganizations,
  getOrganizationById,
  getOrganizationStats,
  getOrganizationStatsCsvFile,
  getOrganizationUserStatsCsvFile,
  createOrganization,
  updateOrganization,
  isIccOrganization,
  getSignupDomains,
  getSignupDomainById,
  createSignupDomain,
  updateSignupDomain,
  deleteSignupDomain,
};

export default organizationService;
