import "./App.css";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import ProtectedRoute from "./components/common/ProtectedRoute";
import { useEffect, useState } from "react";
import httpService from "./services/httpService";
import localStorageService from "./services/localStorageService";
import UserContext from "./contexts/userContext";
import User from "./models/user";
import userService from "./services/userService";
import Profile from "./pages/Profile";
import Signup from "./pages/Signup";
import { AnimatePresence } from "framer-motion";
import ForgotPassword from "./pages/ForgotPassword";
import AccountConfirmation from "./pages/AccountConfirmation";
import ResetPassword from "./pages/ResetPassword";
import RoutePaths from "./pages/routePaths";
import Unauthorized from "./pages/Unauthorized";
import AppAdmin from "./AppAdmin";
import OrganizationContextModel from "./contexts/organizationContextModel";
import OrganizationContext from "./contexts/organizationContext";
import AppUrlParams from "./models/appUrlParams";
import AppUrlParamsContext from "./contexts/appUrlParamsContext";
import { URLSearchParams } from "url";
import NetworkPosition from "./models/networkPosition";

const App = () => {
  const [isLoggedin, setIsLoggedin] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [isInitalized, setIsInitialized] = useState<boolean>(false);

  const [organizationContextModel] = useState<OrganizationContextModel>(
    new OrganizationContextModel()
  );

  const [searchParams] = useSearchParams();
  const [appInitUrlParams, setAppInitUrlParams] = useState<AppUrlParams>(
    new AppUrlParams()
  );

  const parseUrlParams = (params: URLSearchParams) => {
    const appInitUrlParams = new AppUrlParams();

    appInitUrlParams.urlParamsString = params.toString();

    if (params.has("project")) {
      appInitUrlParams.project = params.get("project") ?? "";
      appInitUrlParams.hasProject = true;
      appInitUrlParams.hasUrlParams = true;
    }

    if (params.has("lat")) {
      appInitUrlParams.latitude = !isNaN(Number(params.get("lat")))
        ? Number(params.get("lat"))
        : 0;
      appInitUrlParams.hasLatitude = true;
      appInitUrlParams.hasUrlParams = true;
    }

    if (params.has("lon")) {
      appInitUrlParams.longitude = !isNaN(Number(params.get("lon")))
        ? Number(params.get("lon"))
        : 0;
      appInitUrlParams.hasLogitude = true;
      appInitUrlParams.hasUrlParams = true;
    }

    const networkPosition = new NetworkPosition();
    let hasNetworkPosition: boolean = false;

    if (params.has("road")) {
      networkPosition.road = params.get("road") ?? "";
      networkPosition.hasRoad = true;
      hasNetworkPosition = true;
    }

    if (params.has("direction")) {
      networkPosition.direction = params.get("direction") ?? "";
      networkPosition.hasDirection = true;
      hasNetworkPosition = true;
    }

    if (params.has("segmentid")) {
      networkPosition.segmentId = params.get("segmentid") ?? "";
      networkPosition.hasSegmentId = true;
      hasNetworkPosition = true;
    }

    if (params.has("shapeid")) {
      networkPosition.shapeId = params.get("shapeid") ?? "";
      networkPosition.hasShapeId = true;
      hasNetworkPosition = true;
    }

    if (params.has("chainage")) {
      networkPosition.chainage = !isNaN(Number(params.get("chainage")))
        ? Number(params.get("chainage"))
        : 0;
      networkPosition.hasChainage = true;
      hasNetworkPosition = true;
    }

    if (hasNetworkPosition) {
      appInitUrlParams.hasNetworkPosition = true;
      appInitUrlParams.networkPosition = networkPosition;
      appInitUrlParams.hasUrlParams = true;
    }

    setAppInitUrlParams(appInitUrlParams);
  };

  useEffect(() => {
    if (isInitalized) {
      return;
    }

    console.log("appInit");

    parseUrlParams(searchParams);

    setIsInitialized(true);

    const init = async () => {
      // on app reload (browser refresh) on a different page

      const accessToken = localStorageService.getAccessToken();
      if (accessToken) {
        httpService.setAccessToken(accessToken);

        setIsLoggedin(true);

        // on refresh show an empty screen istead of showing the login screen for a bit until the loggedin user info is loaded
        setIsLoadingUser(true);

        try {
          const user = await userService.getLoggedinUser();
          setUser(user);
        } catch (ex) {
          // got an authorized 403 response as the username was renamed and was still in the localstorage with old username
          userService.logout();
        }

        setIsLoadingUser(false);

        // return to page were it came from on browser refresh
        navigate(location.pathname);
      }
    };

    init();
  }, [isInitalized, location, searchParams, navigate]);

  const onLoginOk = (user: User) => {
    setIsLoggedin(true);
    httpService.setAccessToken(localStorageService.getAccessToken());
    setUser(user);
  };

  const onLogout = () => {
    setIsLoggedin(false);
  };

  if (isLoadingUser) {
    return <div></div>;
  }

  return (
    <>
      <AppUrlParamsContext.Provider value={appInitUrlParams}>
        <UserContext.Provider value={user}>
          <OrganizationContext.Provider value={organizationContextModel}>
            <AnimatePresence initial={false} mode="wait">
              <Routes location={location} key={location.pathname}>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute isLoggedin={isLoggedin}>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/projects"
                  element={
                    <ProtectedRoute isLoggedin={isLoggedin}>
                      <Projects />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute
                      isLoggedin={isLoggedin}
                      denyDemoUser={user?.isDemoUser}
                    >
                      <Profile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/login"
                  element={<Login onLoginOk={onLoginOk} />}
                />
                <Route
                  path="/logout"
                  element={<Logout onLogout={onLogout} />}
                />
                <Route path="/signup" element={<Signup />} />
                <Route
                  path={RoutePaths.AccountConfirmationRoutePath}
                  element={<AccountConfirmation />}
                />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path={RoutePaths.ResetPasswordRoutePath}
                  element={<ResetPassword />}
                />
                <Route
                  path="/admin/*"
                  element={
                    <ProtectedRoute
                      isLoggedin={isLoggedin}
                      isPortalAdmin={user?.isPortalAdmin}
                    >
                      <AppAdmin />
                    </ProtectedRoute>
                  }
                />
                <Route path="/unauthorized" element={<Unauthorized />} />
              </Routes>
            </AnimatePresence>
          </OrganizationContext.Provider>
        </UserContext.Provider>
      </AppUrlParamsContext.Provider>
    </>
  );
};

export default App;
