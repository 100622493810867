import { useContext, useEffect, useState } from "react";
import ProjectInfo from "../../models/projectInfo";
import SettingsModel from "../../models/settings";
import ModalWindow from "../common/overlays/ModalWindow";
import User from "../../models/user";
import UserContext from "../../contexts/userContext";

const Settings: React.FC<{
  settings: SettingsModel;
  project: ProjectInfo;
  onResetLayout: any;
  onSetProjectDefaultLayout: any;
}> = ({ settings, project, onResetLayout, onSetProjectDefaultLayout }) => {
  const user = useContext<User | undefined>(UserContext);

  const [showChainage, setShowChainage] = useState<boolean>(false);
  const [showChainageUnit, setShowChainageUnit] = useState<boolean>(false);
  const [showTableHelperColumns, setShowTableHelperColumns] =
    useState<boolean>(false);
  const [isChainageInMeters, setIsChainageInMeters] = useState<boolean>(false);

  const [showCameraImageUrl, setShowCameraImageUrl] = useState<boolean>(false);

  const [showDistress, setShowDistress] = useState<boolean>(false);

  /* reset layout */

  const [showConfirmResetLayoutWindow, setShowConfirmResetLayoutWindow] =
    useState<Boolean>(false);

  const onResetLayoutClicked = () => {
    setShowConfirmResetLayoutWindow(true);
  };

  const onResetLayoutOk = () => {
    setShowConfirmResetLayoutWindow(false);
    onResetLayout();
  };

  const onResetLayoutCancel = () => {
    setShowConfirmResetLayoutWindow(false);
  };

  /* set project default layout */

  const [
    showConfirmSetProjectDefaultLayoutWindow,
    setShowConfirmSetProjectDefaultLayoutWindow,
  ] = useState<Boolean>(false);

  const onSetProjectDefaultLayoutClicked = () => {
    setShowConfirmSetProjectDefaultLayoutWindow(true);
  };

  const onSetProjectDefaultLayoutOk = () => {
    setShowConfirmSetProjectDefaultLayoutWindow(false);
    onSetProjectDefaultLayout();
  };

  const onSetProjectDefaultLayoutCancel = () => {
    setShowConfirmSetProjectDefaultLayoutWindow(false);
  };

  /* --- */

  const onShowChainageChanged = (event: any) => {
    const checked = event.target.checked;
    setShowChainage(checked);

    settings.showChainage = checked;

    if (settings.onShowChainageChanged) {
      settings.onShowChainageChanged(checked);
    }

    onSettingsChanged();
  };

  const onShowChainageUnitChanged = (event: any) => {
    const checked = event.target.checked;
    setShowChainageUnit(checked);

    settings.showChainageUnit = checked;

    if (settings.onShowChainageUnitChanged) {
      settings.onShowChainageUnitChanged(checked);
    }

    onSettingsChanged();
  };

  const onShowTableHelperColumnsChanged = (event: any) => {
    const checked = event.target.checked;
    setShowTableHelperColumns(checked);

    settings.showTableHelperColumns = checked;

    if (settings.onShowTableHelperColumnsChanged) {
      settings.onShowTableHelperColumnsChanged(checked);
    }

    // onSettingsChanged();
  };

  const onIsChainageInMetersChanged = (event: any) => {
    const checked = event.target.checked;
    setIsChainageInMeters(checked);

    settings.isChainageInMeters = checked;

    if (settings.onIsChainageInMetersChanged) {
      settings.onIsChainageInMetersChanged(checked);
    }
  };

  const onShowCameraImageUrlChanged = (event: any) => {
    const checked = event.target.checked;
    setShowCameraImageUrl(checked);

    settings.showCameraImageUrl = checked;

    for (const callback of settings.onShowCameraImageUrlChangedCallbacks) {
      callback(checked);
    }
  };

  const onShowDistressChanged = (event: any) => {
    const checked = event.target.checked;
    setShowDistress(checked);

    settings.showDistress = checked;

    if (settings.onShowDistressChanged) {
      settings.onShowDistressChanged(checked);
    }
  };

  const onSettingsChanged = () => {
    if (settings.onSettingsChanged) {
      settings.onSettingsChanged(settings);
    }
  };

  useEffect(() => {
    setShowChainage(settings.showChainage);
    setShowChainageUnit(settings.showChainageUnit);
  }, [settings]);

  const getChainageDisplayName = () => {
    if (project.chainageDisplayName && project.chainageDisplayNameShort) {
      return `${project.chainageDisplayName} (${project.chainageDisplayNameShort})`;
    }

    if (project.chainageDisplayName) {
      return project.chainageDisplayName;
    }

    return "Chainage";
  };

  return (
    <div>
      <div className="dropdown-item-padding">
        <button
          // className="dropdown-item"
          className="btn btn-primary btn-sm"
          type="button"
          onClick={onResetLayoutClicked}
        >
          Reset Layout
        </button>
        {showConfirmResetLayoutWindow && (
          <ModalWindow
            title="Reset Layout to Default"
            onOk={onResetLayoutOk}
            onCancel={onResetLayoutCancel}
          >
            <div>Are you sure you want to reset layout to default?</div>
          </ModalWindow>
        )}
      </div>
      <div className="dropdown-item-padding d-flex">
        <div className="d-flex align-items-center">
          <label className="form-check-label" htmlFor="showChainage">
            {getChainageDisplayName()}
          </label>
          <div className="form-switch form-switch-right-text">
            <input
              className="form-check-input form-switch-right-text"
              type="checkbox"
              checked={showChainage}
              onChange={onShowChainageChanged}
              id="showChainage"
            />
          </div>
        </div>
        <div
          className="d-flex align-items-center"
          style={{ marginLeft: "10px" }}
        >
          <label className="form-check-label" htmlFor="showChainageUnit">
            Unit
          </label>
          <div className="form-switch form-switch-right-text">
            <input
              className="form-check-input form-switch-right-text"
              type="checkbox"
              checked={showChainageUnit}
              onChange={onShowChainageUnitChanged}
              id="showChainageUnit"
            />
          </div>
        </div>
      </div>
      {user?.isPortalAdmin && (
        <>
          <br />
          <div className="dropdown-item-padding">
            <div className="badge bg-secondary mb-1">PORTAL ADMIN OPTIONS</div>
          </div>
          <div className="dropdown-item-padding">
            <button
              // className="dropdown-item"
              className="btn btn-primary btn-sm"
              type="button"
              onClick={onSetProjectDefaultLayoutClicked}
            >
              Set Project Default Layout
            </button>
            {showConfirmSetProjectDefaultLayoutWindow && (
              <ModalWindow
                title="Set Project Default Layout"
                onOk={onSetProjectDefaultLayoutOk}
                onCancel={onSetProjectDefaultLayoutCancel}
              >
                <div>Are you sure you want to set project default layout?</div>
              </ModalWindow>
            )}
          </div>
          <div className="dropdown-item-padding d-flex">
            <div className="d-flex align-items-center">
              <label
                className="form-check-label"
                htmlFor="showTableHelperColumns"
              >
                Table Helper Columns
              </label>
              <div className="form-switch form-switch-right-text">
                <input
                  className="form-check-input form-switch-right-text"
                  type="checkbox"
                  checked={showTableHelperColumns}
                  onChange={onShowTableHelperColumnsChanged}
                  id="showTableHelperColumns"
                />
              </div>
            </div>
          </div>
          <div className="dropdown-item-padding d-flex">
            <div className="d-flex align-items-center">
              <label className="form-check-label" htmlFor="isChainageInMeters">
                Chainage in Meters
              </label>
              <div className="form-switch form-switch-right-text">
                <input
                  className="form-check-input form-switch-right-text"
                  type="checkbox"
                  checked={isChainageInMeters}
                  onChange={onIsChainageInMetersChanged}
                  id="isChainageInMeters"
                />
              </div>
            </div>
          </div>
          <div className="dropdown-item-padding d-flex">
            <div className="d-flex align-items-center">
              <label className="form-check-label" htmlFor="showCameraImageUrl">
                Show Camera Image Url
              </label>
              <div className="form-switch form-switch-right-text">
                <input
                  className="form-check-input form-switch-right-text"
                  type="checkbox"
                  checked={showCameraImageUrl}
                  onChange={onShowCameraImageUrlChanged}
                  id="showCameraImageUrl"
                />
              </div>
            </div>
          </div>
          <div className="dropdown-item-padding d-flex">
            <div className="d-flex align-items-center">
              <label className="form-check-label" htmlFor="showDistress">
                Show Distress
              </label>
              <div className="form-switch form-switch-right-text">
                <input
                  className="form-check-input form-switch-right-text"
                  type="checkbox"
                  checked={showDistress}
                  onChange={onShowDistressChanged}
                  id="showDistress"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Settings;
