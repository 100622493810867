import { useEffect, useState } from "react";
import Navigator from "../../models/navigator";
import Navigable from "../../models/navigable";
import GpsPosition from "../../models/gpsPosition";
import ProjectInfo from "../../models/projectInfo";
import {
  styled,
  Tab,
  Tabs,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import roadService from "../../services/roadService";
import NetworkPosition from "../../models/networkPosition";
import unitConverter from "../../util/units/unitConverter";
import HelpIcon from "@mui/icons-material/Help";
import numberHelper from "../../util/numberHelper";

const ShareLocation: React.FC<{
  project: ProjectInfo;
  navigator: Navigator;
  open: any;
}> = ({ project, navigator, open }) => {
  const [shareLink, setShareLink] = useState<string>("");

  const [shareLinkGps, setShareLinkGps] = useState<string>("");

  const SHARE_MODE_GPS: number = 0;
  const SHARE_MODE_NETWORK: number = 1;

  const [shareModeIndex, setShareModeIndex] = useState(SHARE_MODE_GPS);

  const [networkPosition, setNetworkPosition] = useState<NetworkPosition>();

  useEffect(() => {
    const navigable = new Navigable();
    navigable.id = "share";

    const setGpsPosition = (gpsPosition: GpsPosition | null) => {
      const shareLink = computeShareLinkGps(project, gpsPosition);
      setShareLinkGps(shareLink);
    };
    navigable.setGpsPosition = setGpsPosition;

    navigator.attach(navigable);

    return () => navigator.detach(navigable);
  }, [project, navigator]);

  useEffect(() => {
    // eslint-disable-next-line
    if (open == true) {
      // console.log("ShareLocationOpened");
      setShareModeIndex(SHARE_MODE_GPS);
      setShareLink(shareLinkGps);
    }
  }, [open, shareLinkGps]);

  const computeShareLinkGps = (
    project: ProjectInfo,
    gpsPosition: GpsPosition | null
  ): string => {
    if (!gpsPosition) {
      return "";
    }

    // remove last /, ex: http://localhost:3000/
    // const host = window.location.href;
    const host = window.location.href.substring(
      0,
      window.location.href.length - 1
    );
    const projectCode = project.projectCode;
    const shareLink = `${host}?project=${projectCode}&lat=${gpsPosition.latitude}&lon=${gpsPosition.longitude}`;
    return shareLink;
  };

  const computeShareLinkNetwork = (
    project: ProjectInfo,
    networkPosition: NetworkPosition | null
  ): string => {
    if (!networkPosition) {
      return "";
    }

    // remove last /, ex: http://localhost:3000/
    // const host = window.location.href;
    const host = window.location.href.substring(
      0,
      window.location.href.length - 1
    );
    const projectCode = project.projectCode;
    let shareLink = `${host}?project=${projectCode}`;

    if (networkPosition.hasRoad) {
      shareLink += "&road=" + encodeURI(networkPosition.road);
    }

    if (networkPosition.hasDirection) {
      shareLink += "&direction=" + encodeURI(networkPosition.direction);
    }

    if (networkPosition.hasSegmentId) {
      shareLink += "&segmentid=" + encodeURI(networkPosition.segmentId);
    }

    if (networkPosition.hasShapeId) {
      shareLink += "&shapeid=" + encodeURI(networkPosition.shapeId);
    }

    shareLink +=
      "&chainage=" +
      numberHelper.numberTo10Digits(
        unitConverter.metersToMiles(networkPosition.chainage)
      );

    return shareLink;
  };

  const onCopyToClipboard = (event: any) => {
    window.navigator.clipboard.writeText(shareLink);
  };

  const onShareModeChange = (event: React.SyntheticEvent, newValue: number) => {
    setShareModeIndex(newValue);

    if (newValue === SHARE_MODE_NETWORK) {
      onShareModeNetwork();
    } else if (newValue === SHARE_MODE_GPS) {
      setShareLink(shareLinkGps);
    }
  };

  const onShareModeNetwork = async () => {
    if (!navigator.navigatorPosition) {
      return;
    }

    setShareLink("Please wait...");

    const { data: networkPosition } =
      await roadService.getNetworkPositionForNavigatorPosition(
        project.idProject,
        navigator.navigatorPosition
      );

    setNetworkPosition(networkPosition);

    if (networkPosition) {
      const shareLinkNetwork = computeShareLinkNetwork(
        project,
        networkPosition
      );
      setShareLink(shareLinkNetwork);
    }
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <div className="dropdown-item-padding">
      <div className="mb-2">
        <Tabs
          value={shareModeIndex}
          onChange={onShareModeChange}
          aria-label="icon label tabs example"
        >
          <Tab label="GPS" />
          <Tab label="NETWORK" />
        </Tabs>
      </div>
      <div>
        <div className="d-flex align-items-end mb-2">
          <div>Share Link</div>
          {shareModeIndex === SHARE_MODE_NETWORK && (
            <div className="mx-1">
              <HtmlTooltip
                title={
                  <>
                    <Typography color="inherit">Network Mapping</Typography>
                    {/* <em>{"And here's"}</em> <b>{"some"}</b>{" "}
                    <u>{"amazing content"}</u>. {"It's very engaging. Right?"} */}
                    {networkPosition && (
                      <>
                        <b>Mapping Field</b>: Network Field
                        {networkPosition.networkFieldMappings.map(
                          (mapping, index) => (
                            <div key={index}>
                              <b>{mapping.mappingField}</b>:{" "}
                              {mapping.networkFiled}
                            </div>
                          )
                        )}
                      </>
                    )}
                  </>
                }
              >
                <HelpIcon sx={{ fontSize: 20, color: "#1976d2" }} />
              </HtmlTooltip>
            </div>
          )}
        </div>
        {/* reserve width */}
        <div
          id="shareLinkValue"
          style={{
            visibility: "hidden",
            padding: "0.375rem 0.85rem",
            height: "1px",
            marginTop: "-12px",
          }}
        >
          {shareLink}
        </div>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            id="shareLink"
            value={shareLink}
            disabled={true}
          />
        </div>
      </div>
      <div className="mb-2 mt-1 ms-3">
        <span className="link" onClick={onCopyToClipboard}>
          Copy to Clipboard
        </span>
        <a
          className="ms-4 link"
          href={shareLink}
          target="_blank"
          rel="noreferrer"
        >
          Open in New Window
        </a>
      </div>
    </div>
  );
};

export default ShareLocation;
