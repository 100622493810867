import ReactDOM from "react-dom";

const portalElement: any = document.getElementById("overlays");

const BusyIndicator: React.FC<{
  isBusyMessage: string | undefined;
}> = ({ isBusyMessage }) => {
  return (
    <>
      {ReactDOM.createPortal(
        <div className="overlay">
          <div className="d-flex flex-column align-items-center">
            <div className="busy-indicator-spinner spinner-border text-primary" />
            {isBusyMessage && (
              <div className="busy-indicator-text mt-4">{isBusyMessage}</div>
            )}
          </div>
        </div>,
        portalElement
      )}
    </>
  );
};

export default BusyIndicator;
