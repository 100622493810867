import Organization from "./organization";
import User from "./user";

export default class SignupPending {
  public idSignupPending: number = 0;
  public idOrganization: number = 0;
  public idUser: number = 0;
  public organization: Organization | undefined = undefined;
  public user: User | undefined = undefined;
}
