import { useNavigate, useParams } from "react-router-dom";
import BoxContent from "../../components/common/BoxContent";
import { useEffect, useState } from "react";
import formHelper from "../../util/formHelper";
import Organization from "../../models/organization";
import ProjectCollection from "../../models/projectCollection";
import projectService from "../../services/projectService";
import ModalWindow from "../../components/common/overlays/ModalWindow";
import Validator from "../../components/common/Validator";
import ValidatorManager from "../../components/common/validationManager";

const validator = {
  collectionName: "collectionName",
};

const ProjectCollectionEdit: React.FC<{
  organization: Organization | undefined;
}> = ({ organization }) => {
  const { id: idParam } = useParams();
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState<boolean>();

  const [showDeleteWindow, setShowDeleteWindow] = useState<boolean>(false);

  const [validatorManager] = useState<ValidatorManager>(new ValidatorManager());

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [projectCollection, setProjectCollection] = useState<ProjectCollection>(
    new ProjectCollection()
  );

  useEffect(() => {
    const initEdit = async (idProjectCollection: number) => {
      const { data: projectCollection } =
        await projectService.getProjectCollectionById(idProjectCollection);

      setProjectCollection(projectCollection);

      validatorManager.setIsValid(validator.collectionName, true);
    };

    const isNew = idParam === "new";
    setIsNew(isNew);

    if (!isNew) {
      const idProjectCollection = Number(idParam);
      initEdit(idProjectCollection);
    } else {
      if (organization) {
        const newProjectCollection = new ProjectCollection();
        newProjectCollection.organizations.push(organization);
        setProjectCollection(newProjectCollection);
      }
    }
  }, [idParam, validatorManager, organization]);

  const onCollectionNameChanged = (event: any) => {
    const newValue = event.target.value;

    const newProjectCollection = {
      ...projectCollection,
      collectionName: newValue,
    };

    setProjectCollection(newProjectCollection);

    const isValid = newValue.length !== 0;
    validatorManager.setIsValid(validator.collectionName, isValid);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setErrorMessage("");

    if (!validatorManager.validate()) {
      return;
    }

    try {
      if (isNew) {
        await projectService.createProjectCollection(projectCollection);
      } else {
        await projectService.updateProjectCollection(projectCollection);
      }
    } catch (error: any) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }

    navigateAway();
  };

  const onDeleteConfirm = () => {
    setShowDeleteWindow(true);
  };

  const onDeleteOk = async () => {
    setShowDeleteWindow(false);
    await projectService.deleteProjectCollection(
      projectCollection.idProjectCollection
    );
    navigateAway();
  };

  const onDeleteCancel = () => {
    setShowDeleteWindow(false);
  };

  const onCancel = () => {
    navigateAway();
  };

  const navigateAway = () => {
    navigate("/admin/projects");
  };

  return (
    <BoxContent
      title={(isNew ? "New" : "Edit") + " Project Collection"}
      useFullWidth
    >
      <div className="d-flex mb-3">
        <div style={{ fontWeight: "bold", marginRight: "10px" }}>
          Organizaiton
        </div>
        <div>
          {projectCollection.organizations
            .map((o) => o.organizationName)
            .join(", ")}
        </div>
      </div>
      <form
        className="input-form"
        onSubmit={onSubmit}
        onKeyDown={formHelper.onEnterKeyDownPreventSubmit}
      >
        <div className="row">
          <div className="mb-3">
            <label htmlFor="collectionName" className="form-label">
              Project Collection Name
            </label>
            <Validator
              name={validator.collectionName}
              errorMessage="Please enter a project collection name."
              validationManager={validatorManager}
            >
              <input
                type="text"
                className="form-control"
                id="collectionName"
                value={projectCollection.collectionName}
                onChange={onCollectionNameChanged}
              />
            </Validator>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <button type="submit" className="btn btn-primary">
              {isNew ? "Create" : "Save"}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              style={{ marginLeft: "10px" }}
              onClick={onCancel}
            >
              Cancel
            </button>
            <div className="col-md-12 text-center mt-3">
              {errorMessage !== "" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
          </div>
          {!isNew && (
            <div style={{ display: "none" }}>
              <button
                type="button"
                className="btn btn-danger"
                onClick={onDeleteConfirm}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </form>
      {showDeleteWindow && (
        <ModalWindow title="Delete" onOk={onDeleteOk} onCancel={onDeleteCancel}>
          <div>
            {`Are you sure you want to delete the project collection: ${projectCollection?.collectionName}?`}{" "}
            <br />
            <b style={{ color: "red", fontSize: "13px" }}>
              This will also delete all projects in this collection.
            </b>
          </div>
        </ModalWindow>
      )}
    </BoxContent>
  );
};

export default ProjectCollectionEdit;
