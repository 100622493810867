// const saveWorkspace = (workspace: any) => {
//   setObject("workspace", workspace);
// };

// type loadLayoutResult = () => {
//   layout: Layout[];
//   layoutInvisibleItems: Layout[];
// };

// const loadWorkspace: () => {
//   layout: Layout[];
//   layoutInvisibleItems: Layout[];
// } = () => {
//   let layout: Layout[] = [];
//   let layoutInvisibleItems: Layout[] = [];

//   const workspace = getObject("workspace");
//   if (workspace) {
//     layout = workspace.layout;
//     layoutInvisibleItems = workspace.layoutInvisibleItems;
//   }

//   return {
//     layout: layout,
//     layoutInvisibleItems: layoutInvisibleItems,
//   };
// };

// const clearLayout = () => {
//   localStorage.removeItem("layout");
//   localStorage.removeItem("layoutInvisibleItems");
// };

const setLoginData = (accessToken: string) => {
  localStorage.setItem("accessToken", accessToken);
  //localStorage.setItem("user", JSON.stringify(user));
};

const getAccessToken: any = () => {
  return localStorage.getItem("accessToken");
};

const clearLoginData = () => {
  localStorage.removeItem("accessToken");
  //localStorage.removeItem("user");
};

//const getUser = () => getObject("user");

const getIdProjectCollection = () => {
  const idProjectCollectionString = localStorage.getItem("idProjectCollection");
  if (!idProjectCollectionString) {
    return undefined;
  }
  return +idProjectCollectionString;
};

const setIdProjectCollection = (id: number) =>
  localStorage.setItem("idProjectCollection", id.toString());

const clearIdProjectCollection = () =>
  localStorage.removeItem("idProjectCollection");

const getIdProject = () => {
  const idProjectString = localStorage.getItem("idProject");
  if (!idProjectString) {
    return undefined;
  }
  return +idProjectString;
};

const setIdProject = (id: number) =>
  localStorage.setItem("idProject", id.toString());

const clearIdProject = () => localStorage.removeItem("idProject");

// const getProject = () => getObject("project");
// const setProject = (projectInfo: ProjectInfo) =>
//   setObject("project", projectInfo);
// const clearProject = () => clearItem("project");

// const setObject = (key: string, object: any) => {
//   localStorage.setItem(key, JSON.stringify(object));
// };

// const getObject = (key: string) => {
//   const objectString = localStorage.getItem(key);
//   if (objectString) {
//     return JSON.parse(objectString);
//   }
// };

// const clearItem = (key: string) => {
//   localStorage.removeItem(key);
// };

const localStorageService = {
  setLoginData,
  getAccessToken,
  clearLoginData,
  getIdProjectCollection,
  setIdProjectCollection,
  clearIdProjectCollection,
  getIdProject,
  setIdProject,
  clearIdProject,
};

export default localStorageService;
