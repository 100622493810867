import ReactDOM from "react-dom";

const portalElement: any = document.getElementById("overlays");

const Overlay: React.FC<{ children: any }> = ({ children }) => {
  return (
    <>
      {ReactDOM.createPortal(
        <div className="overlay">
          <div className="d-flex flex-column align-items-center">
            {children}
          </div>
        </div>,
        portalElement
      )}
    </>
  );
};

export default Overlay;
