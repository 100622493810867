import byteSize from "byte-size";

function formatBytes(bytes: any, decimals = 0) {
  const { value, unit } = byteSize(bytes, { precision: decimals });

  if (!unit) {
    return "0B";
  }

  return `${value} ${unit.toUpperCase()}`;
}

export default formatBytes;
