import NetworkFieldMapping from "./networkFieldMapping";

export default class NetworkPosition {
  public networkFieldMappings: NetworkFieldMapping[] = [];

  public hasRoad: boolean = false;
  public road: string = "";

  public hasDirection: boolean = false;
  public direction: string = "";

  public hasSegmentId: boolean = false;
  public segmentId: string = "";

  public hasShapeId: boolean = false;
  public shapeId: string = "";

  public hasChainage: boolean = false;
  public chainage: number = 0;
}
