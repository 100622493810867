import { useCallback, useEffect, useState } from "react";
import AdminBoxConent from "../../components/admin/AdminBoxContent";
import UserStat from "../../models/userStat";
import userService from "../../services/userService";
import Moment from "react-moment";
import dateTimeHelper from "../../util/dateTimeHelper";
import OrganizationUserCount from "../../models/organizationUserStat";
import DropdownOption from "../../components/common/DropdownOption";

const ActiveUsers: React.FC<{
  title: string;
  showLiveUsers: boolean;
}> = ({ title, showLiveUsers }) => {
  const [selectedDateRange, setSelectedDateRange] = useState<DropdownOption>();
  const [dateRanges, setDateRanges] = useState<DropdownOption[]>([]);

  const [userStats, setUserStats] = useState<UserStat[]>([]);
  const [allUserStats, setAllUserStats] = useState<UserStat[]>([]);
  const [organizationUserStats, setOrganizationUserStats] = useState<
    OrganizationUserCount[]
  >([]);

  const [selectedOrganizationName, setSelectedOrganizationName] = useState<
    string | undefined
  >();

  const initActiveUsersPastDays = useCallback(
    async (numberOfDaysAgo: number) => {
      const { data: userStats } = await userService.getActiveUsersPastDays(
        numberOfDaysAgo
      );
      setStats(userStats);
    },
    []
  );

  useEffect(() => {
    if (showLiveUsers) {
      const initLiveUsers = async () => {
        const { data: userStats } = await userService.getActiveUsers(
          userService.usersActiveLiveSeconds
        );
        setStats(userStats);
      };

      initLiveUsers();
      return;
    }

    const getDateRanges = (): DropdownOption[] => {
      const dateRanges: DropdownOption[] = [];

      let dateRange = new DropdownOption();

      dateRange = new DropdownOption();
      dateRange.label = "1 Day";
      dateRange.value = 1;

      dateRanges.push(dateRange);

      dateRange = new DropdownOption();
      dateRange.label = "7 Days";
      dateRange.value = 7;

      dateRanges.push(dateRange);

      dateRange = new DropdownOption();
      dateRange.label = "15 Days";
      dateRange.value = 15;

      dateRanges.push(dateRange);

      dateRange = new DropdownOption();
      dateRange.label = "30 Days";
      dateRange.value = 30;

      dateRanges.push(dateRange);

      dateRange = new DropdownOption();
      dateRange.label = "90 Days";
      dateRange.value = 90;

      dateRanges.push(dateRange);

      return dateRanges;
    };

    const dateRanges = getDateRanges();
    setDateRanges(dateRanges);

    const selectedDateRange = dateRanges[0];
    initActiveUsersPastDays(selectedDateRange.value);
  }, [showLiveUsers, initActiveUsersPastDays]);

  useEffect(() => {
    let newUserStats = [...allUserStats];

    if (selectedOrganizationName !== undefined) {
      newUserStats = newUserStats.filter(
        (stat) => stat.organizationName === selectedOrganizationName
      );
    }

    setUserStats(newUserStats);
  }, [selectedOrganizationName, allUserStats]);

  const setStats = (userStats: UserStat[]) => {
    // const { data: userStats } = await userService.getActiveUsersPastDays(
    //   numberOfDaysAgo
    // );
    setUserStats(userStats);
    setAllUserStats(userStats);

    const organizationUserStats: OrganizationUserCount[] = [];
    for (const userStat of userStats) {
      const organizationUserStat = organizationUserStats.find(
        (ost) => ost.organizationName === userStat.organizationName
      );
      if (!organizationUserStat) {
        organizationUserStats.push({
          organizationName: userStat.organizationName,
          userCount: 1,
        });
      } else {
        organizationUserStat.userCount++;
      }
    }
    setOrganizationUserStats(organizationUserStats);
  };

  // const hoursToSeconds = (hours: number): number => {
  //   const seconds: number = hours * 60 * 60;
  //   return seconds;
  // };

  // const daysToSeconds = (days: number): number => {
  //   const seconds: number = days * 24 * 60 * 60;
  //   return seconds;
  // };

  const onDateRangeChanged = async (event: any) => {
    const newValue = event.target.value;
    const numberOfDaysAgo = Number(newValue);

    const selectedOption = dateRanges.find(
      (dr) => dr.value === numberOfDaysAgo
    );

    if (selectedOption) {
      setSelectedDateRange(selectedOption);
    }

    setSelectedOrganizationName(undefined);

    await initActiveUsersPastDays(numberOfDaysAgo);
  };

  const onOrganizationRowClick = (organizationName: string) => {
    if (selectedOrganizationName === organizationName) {
      setSelectedOrganizationName(undefined);
    } else {
      setSelectedOrganizationName(organizationName);
    }
  };

  return (
    <AdminBoxConent
      title={title}
      hasCount={false}
      hasAddNew={false}
      hasCurrentOrganizationDropdown={false}
    >
      {!showLiveUsers && (
        <div style={{ marginBottom: "30px" }}>
          <label htmlFor="dateRangeSelect" className="form-label">
            <h6>Last Days</h6>
          </label>
          <select
            className="form-select"
            aria-label="select"
            id="dateRangeSelect"
            value={selectedDateRange?.value}
            onChange={onDateRangeChanged}
          >
            {dateRanges.map((dateRange, index) => (
              <option key={index.toString()} value={dateRange.value}>
                {dateRange.label}
              </option>
            ))}
          </select>
        </div>
      )}

      <h6>
        Organizations <b>{organizationUserStats.length}</b>
      </h6>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Organization Name</th>
            <th scope="col">Active User Count</th>
          </tr>
        </thead>
        <tbody>
          {organizationUserStats.map(
            (organizationUserStat: OrganizationUserCount, index) => (
              <tr
                key={index.toString()}
                onClick={() =>
                  onOrganizationRowClick(organizationUserStat.organizationName)
                }
                className={
                  selectedOrganizationName ===
                  organizationUserStat.organizationName
                    ? "table-active"
                    : ""
                }
              >
                <td>{organizationUserStat.organizationName}</td>
                <td>{organizationUserStat.userCount}</td>
              </tr>
            )
          )}
        </tbody>
      </table>

      <h6 style={{ marginTop: "30px" }}>
        Users <b>{userStats.length}</b>
      </h6>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Organization Name</th>
            <th scope="col">Email</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Last Login Date</th>
            <th scope="col">Last Activity Date</th>
            <th scope="col">Create Date</th>
          </tr>
        </thead>
        <tbody>
          {userStats.map((userStat: UserStat, index) => (
            <tr key={index.toString()}>
              <td>{userStat.organizationName}</td>
              <td>{userStat.email}</td>
              <td>{userStat.firstName}</td>
              <td>{userStat.lastName}</td>
              <td>
                {userStat.lastLoginDate && (
                  <Moment format={dateTimeHelper.dateTimeFormat}>
                    {userStat.lastLoginDate}
                  </Moment>
                )}
              </td>
              <td>
                {userStat.lastActivityDate && (
                  <Moment format={dateTimeHelper.dateTimeFormat}>
                    {userStat.lastActivityDate}
                  </Moment>
                )}
              </td>
              <td>
                {userStat.createDate && (
                  <Moment format={dateTimeHelper.dateTimeFormat}>
                    {userStat.createDate}
                  </Moment>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </AdminBoxConent>
  );
};

export default ActiveUsers;
