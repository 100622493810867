import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import userService from "../services/userService";
import localStorageService from "../services/localStorageService";
import LoginBoxContent from "../components/common/LoginBoxContent";

import pageHelper from "../util/pageHelper";
import MotionDiv from "../components/common/MotionDiv";
import SignupInfo from "../models/signupInfo";
import RoutePaths from "./routePaths";
import FormMessage from "../components/common/FormMessage";
import AppUrlParamsContext from "../contexts/appUrlParamsContext";

const Login: React.FC<{ onLoginOk: any }> = ({ onLoginOk }) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [showResendAccountConfirmation, setShowResendAccountConfirmation] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const appUrlParams = useContext(AppUrlParamsContext);

  useEffect(() => {
    console.log("loginPageInit");
    pageHelper.hideScrollbars();
  }, []);

  const clearError = () => {
    setErrorMessage("");
    setMessage("");
  };

  const onUsernameChanged = (event: any) => {
    const newValue = event.target.value;
    setUsername(newValue);
  };

  const onPasswordChanged = (event: any) => {
    const newValue = event.target.value;
    setPassword(newValue);
  };

  const onShowResendAccountConfirmation = async () => {
    clearError();

    const signupInfo = new SignupInfo();
    signupInfo.username = username;
    signupInfo.email = username;
    signupInfo.accountConfirmationUrl = RoutePaths.getAccountConfirmationUrl();

    try {
      setIsBusy(true);
      const { data } = await userService.resendAccountConfirmation(signupInfo);
      setIsBusy(false);
      setMessage(data);
    } catch (error: any) {
      setIsBusy(false);
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
    }
  };

  const onSumbit = async (event: any) => {
    event.preventDefault();

    setShowResendAccountConfirmation(false);

    clearError();

    try {
      const data: any = await userService.login(username, password);

      if (!data.success) {
        const errorMessage = data.error;
        setErrorMessage(errorMessage);

        const showResendAccountConfirmation =
          data.showResendAccountConfirmation;

        if (showResendAccountConfirmation) {
          setShowResendAccountConfirmation(true);
        }

        return;
      }

      localStorageService.setLoginData(data.accessToken);

      onLoginOk(data.user);

      if (appUrlParams.hasUrlParams) {
        navigate("/");
      } else {
        const idProjectCollection =
          localStorageService.getIdProjectCollection();
        if (idProjectCollection) {
          navigate("/");
        } else {
          navigate("/projects");
        }
      }
    } catch (error: any) {
      // TODO log maybe if there is an Server unhandled exception or can't connect to server
    }
  };

  return (
    <MotionDiv>
      <LoginBoxContent>
        <form className="input-form" onSubmit={onSumbit}>
          <div className="row">
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Email
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                onChange={onUsernameChanged}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                onChange={onPasswordChanged}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div>
                Forgot password?{" "}
                <Link to="/forgot-password">Reset Password</Link>
              </div>
              <div>
                <button type="submit" className="btn btn-primary">
                  Login
                </button>
              </div>
            </div>
            <div></div>
          </div>
          <div>
            No Account? <Link to="/signup">Signup</Link>
          </div>
          <div className="col-md-12 text-center mt-3">
            {showResendAccountConfirmation && (
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={onShowResendAccountConfirmation}
              >
                Resend Account Confirmation Email
              </button>
            )}
            <FormMessage
              message={message}
              errorMessage={errorMessage}
              isBusy={isBusy}
            />
          </div>
        </form>
      </LoginBoxContent>
    </MotionDiv>
  );
};

export default Login;
