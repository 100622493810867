import LogDirectory from "../services/logDirectory";
import LogService from "../services/logService";
import Filter from "./filter";
import Navigable from "./navigable";
import NavigatorPosition from "./navigatorPosition";
import roadService from "../services/roadService";
import roadManager from "./roadManager";
import interpolation from "../util/interpolation";
import RoadLocatorSegment from "./roadLocatorSegment";
import GpsPosition from "./gpsPosition";

const logService = new LogService(LogDirectory.EnableNavigatorLogging);

export default class Navigator {
  public navigatorPosition: NavigatorPosition | null;

  public filters: Filter[] = [];
  public filtersIdRoads: number[] = [];

  public idRoad: number | null = null;
  public roadLocatorSegmentIndex: number = -1;
  public roadLocatorSegment: RoadLocatorSegment | null = null;
  public roadLocatorSegments: RoadLocatorSegment[] = [];

  public gpsPosition: GpsPosition | null = null;

  public isPlaying: boolean = false;

  private navigables: Navigable[] = [];

  constructor() {
    this.navigatorPosition = null;
  }

  public attach(navigable: Navigable): void {
    logService.log(`${navigable.id}:navigator:attach()`);
    this.navigables.push(navigable);
  }

  public detach(navigable: Navigable): void {
    logService.log(`${navigable.id}:navigator:detach()`);
    this.navigables = this.navigables.filter(
      (observer) => observer !== navigable
    );
  }

  private fixChainage(navigatorPosition: NavigatorPosition) {
    if (this.roadLocatorSegment !== null) {
      navigatorPosition.chainage = interpolation.linearInterpolation(
        this.roadLocatorSegment.startDistance,
        this.roadLocatorSegment.startChainage,
        this.roadLocatorSegment.endDistance,
        this.roadLocatorSegment.endChainage,
        navigatorPosition.distance
      );
    }
  }

  public async move(navigatorPosition: NavigatorPosition, sender?: Navigable) {
    //console.log("navigator:move", navigatorPosition);

    this.navigatorPosition = navigatorPosition;

    this.roadLocatorSegmentIndex = roadManager.getRoadLocatorSegmentIndex(
      navigatorPosition,
      this.roadLocatorSegments
    );

    if (this.roadLocatorSegmentIndex === -1) {
      if (this.idRoad === navigatorPosition.idRoad) {
        this.setFilters(navigatorPosition.idProject, []);
      }

      this.roadLocatorSegments = await roadService.getRoadLocatorSegments(
        navigatorPosition.idProject,
        navigatorPosition.idRoad,
        this.filters
      );

      this.idRoad = navigatorPosition.idRoad;

      this.roadLocatorSegmentIndex = roadManager.getRoadLocatorSegmentIndex(
        navigatorPosition,
        this.roadLocatorSegments
      );

      if (this.roadLocatorSegmentIndex === -1) {
        this.roadLocatorSegments = await roadService.getRoadLocatorSegments(
          navigatorPosition.idProject,
          navigatorPosition.idRoad,
          []
        );

        this.roadLocatorSegmentIndex = roadManager.getRoadLocatorSegmentIndex(
          navigatorPosition,
          this.roadLocatorSegments
        );
      }
    }

    this.roadLocatorSegment =
      this.roadLocatorSegments[this.roadLocatorSegmentIndex];

    if (!navigatorPosition.chainage) {
      this.fixChainage(navigatorPosition);
    }

    let navigables = this.navigables;

    const moves: Promise<void>[] = [];
    for (const navigable of navigables) {
      if (!this.navigables.find((o) => o === navigable)) {
        continue;
      }

      if (navigable !== sender) {
        if (navigable.navigatorMove) {
          const promise: Promise<void> =
            navigable.navigatorMove(navigatorPosition);
          moves.push(promise);
        }
      }
    }
    await Promise.all(moves);

    // for (const navigable of navigables) {
    //   if (!this.navigables.find((o) => o === navigable)) {
    //     continue;
    //   }

    //   if (navigable !== sender) {
    //     if (navigable.navigatorMove) {
    //       await navigable.navigatorMove(navigatorPosition);
    //     }
    //   }
    // }

    for (const navigable of navigables) {
      if (!this.navigables.find((o) => o === navigable)) {
        continue;
      }
      if (navigable.navigatorMoveAllCompleted) {
        await navigable.navigatorMoveAllCompleted();
      }
    }
  }

  public playbackIsPlaying(isPlaying: boolean, sender?: Navigable): void {
    this.isPlaying = isPlaying;
    let navigables = this.navigables;
    for (const navigable of navigables) {
      if (navigable.playbackIsPlayingChanged) {
        navigable.playbackIsPlayingChanged(isPlaying);
      }
    }
  }

  public async setFilters(
    idProject: number,
    filters: Filter[],
    sender?: Navigable
  ): Promise<void> {
    this.filters = filters;

    if (filters.length > 0) {
      const { data: idRoads } = await roadService.getIdRoadsForFilters(
        idProject,
        filters
      );
      this.filtersIdRoads = idRoads;
    } else {
      this.filtersIdRoads = [];
    }

    let navigables = this.navigables;
    for (const navigable of navigables) {
      if (navigable !== sender) {
        if (navigable.setFilters) {
          await navigable.setFilters(filters);
        }
      }
    }
  }

  public setGpsPosition(
    gpsPosition: GpsPosition | null,
    sender?: Navigable
  ): void {
    let navigables = this.navigables;
    for (const navigable of navigables) {
      if (navigable !== sender) {
        if (navigable.setGpsPosition) {
          navigable.setGpsPosition(gpsPosition);
        }
      }
    }
  }
}
